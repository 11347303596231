import { faExternalLinkAlt, faToolbox } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { graphql } from 'gatsby';
import * as PropTypes from 'prop-types';
import React from 'react';
import Container from 'react-bootstrap/Container';
import Img from 'gatsby-image';
import Card from 'react-bootstrap/Card';
import ListGroup from 'react-bootstrap/ListGroup';
import BackgroundImage from 'gatsby-background-image';
import Button from 'react-bootstrap/Button';
import LocalizedLink from '../../../components/LocalizedLink';
import SEO from '../../../components/SEO';
import Layout from '../../../components/Layout';

export default function SAPMaintenance({ location, data, pathContext: { locale, pageSlugs } }) {
  const { markdownFileText: { frontmatter } } = data;

  return (
    <Layout location={location} locale={locale} pageSlugs={pageSlugs}>
      <SEO
        title={frontmatter.title}
        description={frontmatter.description}
        language={locale}
      />
      <BackgroundImage
        tag="div"
        className="d-table w-100 h-175-px"
        fluid={data.datArtSAPHeaderImage.childImageSharp.fluid}
      >
        <div className="d-table-cell align-middle text-center">
          <h1
            className="d-inline-block title-font-size text-white text-center bg-darken rounded p-4
            mx-4"
          >
            {frontmatter.mainTitle}
          </h1>
        </div>
      </BackgroundImage>
      {/* <Container className="p-4 min-vh-100"> */}
      <Container className="p-4">
        <p>{frontmatter.introText}</p>
        <div className="bg-grey w-100 p-4 mx-auto mt-4 rounded special-shadow">
          <h2 className="h4 text-info">{frontmatter.internalResourcesTitle}</h2>
          <p className="lead">{frontmatter.internalResourcesText}</p>
          <hr />
          <h2 className="h4 text-info">{frontmatter.costsControlTitle}</h2>
          <p className="lead">{frontmatter.costsControlText}</p>
          <hr />
          <h2 className="h4 text-info">{frontmatter.optimiseOrganisationTitle}</h2>
          <p className="lead">{frontmatter.optimiseOrganisationText}</p>
          <hr />
          <h2 className="h4 text-info">{frontmatter.isPotentialTitle}</h2>
          <p className="lead">{frontmatter.isPotentialText}</p>
          <hr />
          <h2 className="h4 text-info">{frontmatter.supportTitle}</h2>
          <p className="lead">{frontmatter.supportText}</p>
          <hr />
          <h2 className="h4 text-info">{frontmatter.toolsTitle}</h2>
          <p className="lead">{frontmatter.toolsText}</p>
        </div>
        <p className="lead text-center mt-5 font-weight-bold text-primary">
          {frontmatter.contactText}
        </p>
        <p className="text-center">
          <LocalizedLink
            to="/contact/#contact"
            state={{ sourcePage: frontmatter.title }}
          >
            <Button>{frontmatter.contactButtonText}</Button>
          </LocalizedLink>
        </p>
      </Container>
    </Layout>
  );
}

SAPMaintenance.propTypes = {
  location: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
  pathContext: PropTypes.object.isRequired,
};

export const query = graphql`
    fragment pagesHeaderFluidImage on File {
        childImageSharp {
            fluid(maxWidth: 1500, quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
            }
        }
    }

    query($locale: String!) {
        markdownFileText: markdownRemark(frontmatter: {lang: {eq: $locale}} fields: {fileName:
        {eq: "sap-services-maintenance"}}) {
            frontmatter {
                title
                description

                mainTitle

                introText
                
                internalResourcesTitle
                internalResourcesText
                costsControlTitle
                costsControlText
                optimiseOrganisationTitle
                optimiseOrganisationText
                isPotentialTitle
                isPotentialText
                supportTitle
                supportText
                toolsTitle
                toolsText
                
                contactText
                contactButtonText
            }
        }
        
        datArtSAPHeaderImage: file(relativePath: {regex: "/datArtSAPHeaderImage/"}) {
            ...pagesHeaderFluidImage
        }
    }
`;
